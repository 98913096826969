body {
    height: 100vh;
    background-color: #EEF2F5;
    display: flex;
    justify-content: center;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.tooltipOpacity.show {
    opacity:1 !important;
}
.home-container{
    max-width:944px;
    margin: auto;
    padding: 0px;
}
.home-card-section{
    display: grid;
    grid-template-columns: repeat(2, 1fr [auto-fill]);
    gap: 20px;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap
}
.home-card-container{
    flex-basis: 40%;
}
.home-card-container > div{
    height: 100%;
}
.home-card-header{
    display:flex;
    justify-content:space-between; 
    gap: 16px;
    align-items:center;
    height:100px;
}
.home-card-header h3{
    margin: 0px;
}
.home-card-text{
    min-height: 120px;
    min-width:250px;
}
@media only screen and (max-width:900px) {
    .home-card-text{
        margin-bottom: 10px;
        min-height: 140px;
    }
    .home-card-header{
        flex-direction: column-reverse;
        gap: 0px;
    }
    .home-card-header h3{
        font-size: 17px;
    }
}

@media only screen and (max-width:675px) {
    .home-card-section {
        grid-template-columns: 1fr;
    }
}