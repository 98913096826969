body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*  sans-serif;*/
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

::-webkit-scrollbar {
    /*display: none;*/
}

.question-wrapper .css-gciibc {
    margin-top: 8px !important;
}

.question-wrapper .css-1xmcseo {
    margin-top: 24px !important;
}

.question-wrapper .css-10cukvq {
    color: #000000;
}
