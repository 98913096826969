.wrapper {
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12)) drop-shadow(0px 0.3px 1px rgba(0, 0, 0, 0.006));
  background-color: #ffffff;
  width: 100%;
  height: 56px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.innerWrapper {
  padding-left: 24px;
  padding-right:24px
}